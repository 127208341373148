<template>
  <div class="driver-detail">
    <div v-if="driver">
      <div class="bg-dark">
        <meta-data
          :meta-title="driver.metaTitle"
          :meta-description="driver.metaDescription"
          :key="driver.metaTitle"/>
        <page-header
          v-if="!loading"
          :layout="'driver'"
          :background-image="driver && driver.headerImage ? `https://media.graphassets.com/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/compress/${driver.headerImage.handle}` : ''"
          :will-have-background-image="true"
          :break-after="true"
          :break-after-to-dark="true"
          :loading="loading"/>
        <div class="padding--bottom-4">
          <div class="container-fluid">
            <loader v-if="loading" :inverted="true"/>
            <driver-stage :driver="driver"/>
            <event-statistic-line :dark-mode="true" v-if="event && drivers && wigeData._event && wigeData._event.drivers && wigeData._event.drivers.length" :data="wigeData" :driver="driver"></event-statistic-line>
            <driver-car-info v-if="driver.driverSeasons.length && driver.driverSeasons[0].car && driver.driverSeasons[0].car.carInfoImage" :car="driver.driverSeasons[0].car"/>
          </div>
        </div>
        <div class="padding--bottom-4 padding--top-4">
          <div class="container-fluid">
            <template v-for="(module, index) in modules">
              <vertical-slider
                v-if="module.moduleName === 'VerticalSlider'"
                :key="`vertical-slider-${module.id}-${index}`"
                :class-name="'vertical-slider--driverDetail'"
                :slider-data="module"/>
            </template>
          </div>
        </div>
        <div class="container-fluid">

        </div>
      </div>
      <div class="position-relative bg-dark">
        <break-after :to-white="true" :inverted="true" :behind="false"></break-after>
      </div>
      <div class="bg-white padding--bottom-4">
        <div class="container-fluid">
          <template v-for="(module) in modules">
            <plain-text v-if="module.moduleName === 'ModuleText'" :key="`text-${module.id}`" :fade="true" :data-obj="module" :class="'text-default'"/>
            <youtube v-if="module.moduleName === 'ModuleVideo'" :key="`youtube-${module.id}`" :data-obj="module" :class="'view padding--top-4 padding--bottom-4'"/>
            <image-text v-if="module.moduleName === 'ModuleImageText'" :key="`image-text-${module.id}`" :data-obj="module" :class="'text-default'"/>
            <image-gallery v-if="module.moduleName === 'ModuleGallery'" :key="`image-gallery-${module.id}`" :data-obj="module" :class="'text-default'"/>
          </template>
        </div>
      </div>
    </div>
    <div v-if="noDriver && drivers.length">
      <meta-data
        :meta-title="slug ? $t('driverDetail.noDriver') : $t('driverDetail.title')"
        :meta-description="slug ? $t('driverDetail.noDriverText') : ''"
        :key="''"/>
      <page-header
        class="bg-default text-white"
        :headline="slug ? $t('driverDetail.noDriver') : $t('driverDetail.title')"
        :class-list="''"
        :break-after-to-dark="true"
        :background-image="''"/>
      <div class="bg-dark text-default">
        <div class="pt-5 pb-5 container-fluid">
          {{ slug ? $t('driverDetail.noDriverText') : '' }}

          <div class="row mt-5 mb-5">
            <template v-for="(driver, index) in drivers">
              <div class="col-12 col-md-6 col-lg-4 mb-4 text-align--center view" :key="`driver-${index}`">
                <router-link :to="`/${$i18n.locale}/${$t('navigation.driver')}/${driver.slug}`">
                  <div class="bg-default p-3 driver-card fade-in-child fade-in--bottom">
                    <div class="position-relative overflow-hidden mb-2 w-50 mx-auto">
                      <background-image
                        :scale="'original'"
                        :intersect="true"
                        :url="driver.image ? `https://media.graphassets.com/resize=w:650,fit:crop/quality=value:70/compress/${driver.image.handle}` : pixel"></background-image>
                    </div>
                    <h4 class="h4 text-italic primary m-0" v-html="Math.abs(driver.driverSeasons[0].driverNumber) || '&nbsp;'"/>
                    <div>{{ driver.firstName }} <b>{{ driver.lastName }}</b></div>
                  </div>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import MetaData from '@/components/MetaData';
import PageHeader from '@/components/layout/PageHeader';
import Loader from '@/components/layout/Loader';
import DriverStage from '@/components/driver/DriverStage';
import DriverCarInfo from '@/components/driver/DriverCarInfo';
import VerticalSlider from '@/components/VerticalSlider';
import PlainText from '@/components/PlainText';
import Youtube from '@/components/Youtube';
import ImageText from '@/components/ImageText';
import ImageGallery from '@/components/ImageGallery';
import BreakAfter from '@/components/layout/BreakAfter';
import WigeDataModel from '../entity/WigeDataModel';
import EventStatisticLine from '@/components/events/EventStatisticLine';
import BackgroundImage from '@/components/layout/BackgroundImage';

export default {
  name: 'DriverDetail',
  components: { BackgroundImage, EventStatisticLine, BreakAfter, ImageGallery, ImageText, Youtube, PlainText, VerticalSlider, DriverCarInfo, DriverStage, Loader, PageHeader, MetaData },
  props: {
    raceSeries: {
      type: String,
      required: true,
      default: 'DTM'
    }
  },
  data () {
    return {
      slug: this.$route.params.slug,
      currentYear: 0,
      driver: null,
      loading: true,
      modules: null,
      noDriver: false,
      wigeData: null,
      event: null,
      drivers: [],
      pixel: AppConst.PIXEL
    };
  },
  methods: {
    resetDrivers () {
      this.drivers.forEach((driver) => {
        driver.view = false;
      });
    },
    viewDriver (driver) {
      this.$set(driver, 'view', true);
      driver.view = true;
    },
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=drivers',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          // const year = new Date().getFullYear();
          const year = 2024; // TODO: remove 2024
          const drivers = [];
          response.drivers.forEach((driver) => {
            driver.view = false;
            let age = 0;
            if (driver.appBirth) {
              const split = driver.appBirth.split('.');
              const birthDay = new Date(split[2], split[1] - 1, split[0]);
              const ageDifMs = Date.now() - birthDay.getTime();
              const ageDate = new Date(ageDifMs); // miliseconds from epoch
              age = Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            driver.age = age;
            if (driver.webVisibility) {
              driver.driverSeasons = driver.driverSeasons.sort((a, b) => b.season - a.season);
              const season = driver.driverSeasons.find((driverSeason) => {
                return driverSeason.raceSeries.toUpperCase() === this.raceSeries.toUpperCase() && driverSeason.season === year;
              });
              if (season) {
                drivers.push(Object.assign(driver, season));
              }
            }
          });
          this.wigeData.parseDriversResponse(drivers);
          this.$nextTick(() => {
            // this.drivers = this.shuffle(drivers);
            this.drivers = drivers.sort((a, b) => {
              return a.driverSeasons[0].driverNumber - b.driverSeasons[0].driverNumber;
            });
          });
        });
    },
    async fetchDriverDetail () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=driverDetail&slug=${this.slug}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.drivers && response.drivers.length) {
            this.driver = response.drivers[0];
            this.driver.metaTitle = this.driver.metaTitle || `${this.driver.firstName} ${this.driver.lastName}`;
            this.driver.metaDescription = this.driver.metaDescription || this.driver.appCareer;
            // setup modules
            if (this.driver.moduleSets && this.driver.moduleSets.length > 0) {
              this.driver.moduleSets.forEach((moduleEntry) => {
                this.modules = moduleEntry.modules || null;
              });
            }
            let age = '';
            if (this.driver.appBirth) {
              const split = this.driver.appBirth.split('.');
              const birthDay = new Date(split[2], split[1] - 1, split[0]);
              const ageDifMs = Date.now() - birthDay.getTime();
              const ageDate = new Date(ageDifMs); // miliseconds from epoch
              age = Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            this.driver.age = age;
            this.driver.driverSeasons = this.driver.driverSeasons.sort((a, b) => b.season - a.season);
            this.$store.commit(AppConst.ADD_LOCALIZATIONS, [
              { locale: 'en', slug: this.driver.slug },
              { locale: 'de', slug: this.driver.slug }
            ]);
            if (this.driver.driverSeasons.length) {
              this.raceSeries = this.driver.driverSeasons[0].raceSeries;
              this.fetchStandings();
            }
          } else {
            this.noDriver = true;
          }
          this.loading = false;
          this.$store.commit('page/SET_RACESERIES', (this.driver && this.driver.raceSeries && this.driver.raceSeries.length ? this.driver.raceSeries : ['DTM']));
          this.$store.dispatch('page/raceSeriesLoaded', false);
        });
    },
    async fetchStandings () {
      let startDate = new Date((new Date().getFullYear()) + '-01-01');
      // let startDate = new Date(this.driver.driverSeasons[0].season + '-01-01');
      let endDate = new Date((new Date().getFullYear()) + '-12-31');
      // let endDate = new Date(this.driver.driverSeasons[0].season + '-12-31');
      if (!this.forceSeason) {
        if (this.baseEvent) {
          const startTime = new Date(this.baseEvent.startTime);
          const endTime = new Date(this.baseEvent.endTime);
          startDate = new Date(startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate());
          endDate = new Date(endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate());
          this.year = startDate.getFullYear();
        }
        if (this.settings && 'startDate' in this.settings && 'endDate' in this.settings) {
          startDate = new Date(this.settings.startDate);
          endDate = new Date(this.settings.endDate);
          this.year = startDate.getFullYear();
        }
      }
      this.event = {};
      const schema = this.driver.driverSeasons[0].raceSeries === 'DTM' ? 'wigeStandings' : 'wigeStandingsTrophy';
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=' +
          schema +
          '&raceSeries=' +
          this.driver.driverSeasons[0].raceSeries +
          '&startDate=' +
          (startDate.getFullYear() + '-' + ('00' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('00' + startDate.getDate()).slice(-2)) +
          '&endDate=' +
          (endDate.getFullYear() + '-' + ('00' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('00' + endDate.getDate()).slice(-2)),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.wigeData.parseStandingsResponse(response, this.driver.driverSeasons[0].raceSeries);
          this.event = this.wigeData.event;
        })
        .catch(() => {
          //
        });
    },
    shuffle (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    getSlugsByRaceSeries () {
      if (this.raceSeries === 'DTM') {
        return [
          { locale: 'en', slug: 'driver' },
          { locale: 'de', slug: 'fahrer' }
        ];
      }
      return [
        { locale: 'en', slug: 'driver-dtm-trophy' },
        { locale: 'de', slug: 'fahrer-dtm-trophy' }
      ];
    }
  },
  watch: {
    '$route' () {
      this.loading = true;
      this.noDriver = false;
      this.slug = this.$route.params.slug;
      this.driver = null;
      if (this.slug) {
        this.fetchDriverDetail();
      } else {
        this.resetDrivers();
        this.noDriver = true;
        this.loading = false;
      }
      this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.getSlugsByRaceSeries());
      this.fetchDrivers();
    }
  },
  mounted () {
    this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.getSlugsByRaceSeries());
    this.wigeData = new WigeDataModel();
    this.drivers = [];
    this.fetchDrivers();
    if (this.slug) {
      this.fetchDriverDetail();
    } else {
      this.noDriver = true;
      this.$store.commit('page/SET_RACESERIES', (this.driver && this.driver.raceSeries && this.driver.raceSeries.length ? this.driver.raceSeries : ['DTM']));
      this.$store.dispatch('page/raceSeriesLoaded', false);
    }
  }
};
</script>

<style lang="scss">
  .driver-detail {
    .driver-card {
      border-radius: 5px;
      box-shadow: 0 2px 10px rgba($color-bg-dark, .4);
      transition: all .3s;
      &:hover {
        box-shadow: 0 0 0 rgba($color-bg-dark, .4);
        background: $color-bg-dark;
      }
    }
  }
</style>

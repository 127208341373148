<template>
  <div id="home" v-if="!this.raceSeriesLoaded">
    <meta-data v-if="page.metaTitle"
      :key="page.metaTitle"
      :meta-title="page.metaTitle"
      :meta-description="page.metaDescription"
      :no-index="page.noIndex"/>
    <template v-if="page && page.modules">
      <home-header
        v-if="!page.stageVideo || (page.stageVideo && width > 768)"
        :background-image="page.stageMedia"
        :video="page.stageVideo ? page.stageVideo.url : ''"
        :headline="page.stageHeadline"
        :sub-headline="page.stageTopline"
        :sub-text="page.stageSubheadline"
        :buttons="page.stageButtons"
        :dtm-trophy="isDtmTrophy"
      ></home-header>
      <page-header
        v-if="page.stageVideo && width <= 768"
        :layout="'home'"
        :headline="page.stageHeadline"
        :class-list="'page-header--home ' + isDtmTrophy ? 'dtm-trophy' : ''"
        :break-after-to-yellow="isDtmTrophy"
        :sub-text="page.stageSubheadline"
        :sub-headline="page.stageTopline"
        :buttons="page.stageButtons"
        :video="page.stageVideo.url"
        :background-image-mobile="page ? page.stageMediaMobile ? `https://media.graphassets.com/resize=w:780,fit:crop/output=format:jpg/quality=value:70/compress/${page.stageMediaMobile.handle}` : '' : ''"
        :background-image="page ? page.stageMedia ? `https://media.graphassets.com/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/compress/${page.stageMedia.handle}` : '' : ''"/>
      <template v-for="(moduleEntry, index) in page.modules[status]">
        <driver-slider v-if="moduleEntry.module === 'driver'"
                       :key="index"
                       :no-images="moduleEntry.noImages ? moduleEntry.noImages : false"
                       :year="2024"
                       :raceSeries="moduleEntry.raceSeries || 'DTM'"/>
        <event-banner v-if="moduleEntry.module === 'event-banner'"
                      :key="index"
                      :status="status"
                      :youtube-stream="'youtubestream' in moduleEntry && moduleEntry.youtubestream ? moduleEntry.youtubestream : ''"
                      :time-threshold="'time' in moduleEntry && moduleEntry.time ? parseInt(moduleEntry.time, 10) : 10"/>
        <news-overview v-if="moduleEntry.module === 'news'"
                       :key="index"
                       :is-dtm-trophy="isDtmTrophy"/>
        <partner v-if="moduleEntry.module === 'partner'"
                 :key="index"
                 :is-supplier="moduleEntry.isSupplier"/>
        <race-timeline v-if="moduleEntry.module === 'timeline'"
                       :key="index"
                       :year="'year' in moduleEntry ? moduleEntry.year : ''"/>
        <season-review v-if="moduleEntry.module === 'seasonreview'"
                       :key="index"
                       :season="'season' in moduleEntry ? moduleEntry.season.toString() : ''"
                       :primary-headline="'headline' in moduleEntry ? moduleEntry.headline.toString() : ''"/>
        <top-products v-if="moduleEntry.module === 'shop'" :key="index"/>
        <walls-io v-if="moduleEntry.module === 'social'" :key="index"/>
        <wige-livetiming v-if="moduleEntry.module === 'live'"
                         :key="index"
                         :event="'event' in moduleEntry ? moduleEntry.event.toString() : 'dtm'" />
        <newsletter v-if="moduleEntry.module === 'newsletter'"
                    :key="`newsletter-${index}`"
                    :config-de="moduleEntry.de"
                    :config-en="moduleEntry.en"
                    :class="'text-default bg-white padding--top-4 padding--bottom-4'"/>
        <info-boxes v-if="moduleEntry.module === 'infoBoxes'"
                    :key="`info-boxes-${moduleEntry.id}`"
                    :ids="moduleEntry.ids"
                    :class="'container-fluid'"/>
        <generic v-if="moduleEntry.module === 'generic'"
                 :key="`generic-${moduleEntry.id}`"
                 :id="moduleEntry.id"></generic>
        <team-list v-if="moduleEntry.module === 'teams'"
                   :key="`teams-${index}`"
                   :race-series="moduleEntry.raceSeries || 'DTM'" />
      </template>
    </template>
  </div>
</template>

<script>
import NewsOverview from '../components/news/NewsOverview';
import Partner from '../components/Partner';
import WallsIo from '../components/WallsIo';
import AppConst from '../config/app.const';
import MetaData from '../components/MetaData';
import RaceTimeline from '../components/RaceTimeline';
import HomeHeader from '../components/layout/HomeHeader';
import DriverSlider from '../components/DriverSlider';
import TopProducts from '../components/TopProducts';
import PageHeader from '../components/layout/PageHeader';
import Settings from '../config/settings';
import EventBanner from '../components/events/EventBanner';
import WigeLivetiming from '../components/wige/WigeLivetiming';
import SeasonReview from '@/components/SeasonReview';
import Newsletter from '@/components/Newsletter';
import InfoBoxes from '@/components/InfoBoxes';
import Generic from '../components/Generic';
import TeamList from '@/components/driver/TeamList';

export default {
  name: 'Home',
  components: {
    InfoBoxes,
    Newsletter,
    SeasonReview,
    WigeLivetiming,
    EventBanner,
    PageHeader,
    TopProducts,
    DriverSlider,
    HomeHeader,
    RaceTimeline,
    MetaData,
    NewsOverview,
    Partner,
    WallsIo,
    Generic,
    TeamList
  },
  data () {
    return {
      loading: false,
      page: {},
      status: 'default',
      width: 0,
      height: 0,
      events: [],
      selected: {},
      dates: {
        first: null,
        last: null,
        range: [],
        today: new Date()
      },
      retryMax: 1,
      retryCount: 0
    };
  },
  computed: {
    querySlugName: function () {
      if (this.$route.name === 'homeTrophy') {
        return 'homeTrophy';
      }
      return 'home2020';
    },
    isDtmTrophy: function () {
      return this.$store.getters['page/raceSeries'].includes('DTMTrophy');
    },
    raceSeriesLoaded: function () {
      return this.$store.getters['page/raceSeriesLoading'];
    }
  },
  methods: {
    async fetchEvents () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=eventsRacetrack',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          let nextEvent = -1;
          const events = response.events;
          const now = new Date();
          const today = now.getFullYear() + ('00' + (now.getMonth() + 1)).slice(-2) + ('00' + now.getDate()).slice(-2);
          let status = 'default';
          events.forEach((event, index) => {
            event.startDate = new Date(event.startTime);
            event.endDate = new Date(event.endTime);
            event.countIndex = index;
            event.start = event.startDate.getFullYear() + ('00' + (event.startDate.getMonth() + 1)).slice(-2) + ('00' + event.startDate.getDate()).slice(-2);
            event.end = event.endDate.getFullYear() + ('00' + (event.endDate.getMonth() + 1)).slice(-2) + ('00' + event.endDate.getDate()).slice(-2);
            const dateBefore = new Date(event.startTime);
            dateBefore.setDate(dateBefore.getDate() - 4);
            const dateAfter = new Date(event.endTime);
            dateAfter.setDate(dateAfter.getDate() + 4);
            const daysBefore = dateBefore.getFullYear() + ('00' + (dateBefore.getMonth() + 1)).slice(-2) + ('00' + dateBefore.getDate()).slice(-2);
            const daysAfter = dateAfter.getFullYear() + ('00' + (dateAfter.getMonth() + 1)).slice(-2) + ('00' + dateAfter.getDate()).slice(-2);
            // set first race date this year
            if (!this.dates.first || (this.dates.first && this.dates.first > event.startDate)) {
              this.dates.first = event.startDate;
            }
            // set last race date this year
            if (!this.dates.last || (this.dates.last && this.dates.last < event.startDate)) {
              this.dates.last = event.startDate;
            }
            if (nextEvent < 0 && event.startDate > today) {
              nextEvent = index;
            }
            if (today >= daysBefore && today < event.start) {
              status = 'before';
            }
            if (today <= daysAfter && today > event.end) {
              status = 'after';
            }
            if (today >= event.start && today <= event.end) {
              status = 'live';
            }
            if (status === 'live') {
              nextEvent = index;
            }
            if (status !== 'default') {
              this.status = status;
            }
          });
          if (nextEvent < 0) {
            nextEvent = events.length - 1;
          }
          for (let i = this.dates.first.getMonth() - 2; i < 12; i++) {
            const tmpDate = new Date(this.dates.first.getFullYear(), i, 1);
            const month = {
              date: tmpDate,
              // name: this.$i18n.d('month', tmpDate),
              events: []
            };
            events.forEach(event => {
              if (event.startDate.getMonth() === tmpDate.getMonth() && event.startDate.getFullYear() === tmpDate.getFullYear()) {
                month.events.push(event);
              }
            });
            this.dates.range.push(month);
          }
          this.events = response.events;
          this.selected = nextEvent;
          this.changeSelected = false;
          this.loading = false;
        });
    },
    async fetchPage () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=page&slug=${this.querySlugName}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.page = response.pages[0];
          this.$store.commit('page/SET_RACESERIES', ['DTM']);
          this.$store.dispatch('page/raceSeriesLoaded', false);
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchPage, 3000);
          }
        });
    },
    resize (values) {
      this.width = values[0];
      this.height = values[1];
    }
  },
  watch: {
    $route () {
      this.fetchPage();
    }
  },
  created () {
    this.fetchEvents();
    this.fetchPage();
    this.resize(this.$store.getters['scroll/resize']);
    this.unwatchResize = this.$store.watch(() => this.$store.getters['scroll/resize'], resize => {
      this.resize(resize);
    });
  },
  destroyed () {
    this.unwatchResize();
  }
};
</script>
